// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from 'firebase/firestore';
import { getStorage } from "firebase/storage";
import { getAuth,  } from "firebase/auth";
import { getAnalytics } from "firebase/analytics";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyCYKjWgfgIWrrsIV9CTufH-BKejaBP6HxA",
    authDomain: "propelyo.firebaseapp.com",
    projectId: "propelyo",
    storageBucket: "propelyo.appspot.com",
    messagingSenderId: "79168948098",
    appId: "1:79168948098:web:eb3307d702ce28261ab19b",
    measurementId: "G-M1LJFSKWDR"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const firestore = getFirestore();
export const storage = getStorage();
export const analytics = getAnalytics(app);