import state from "../data/store";
import { firestore } from "../firebase";
import { doc, collection, getDocs, getDoc } from 'firebase/firestore';


class ContentServiceImpl {
    async loadAllVideos() {
        const videosRef = collection(firestore, "/videos");
        const videosSnapshot = await getDocs(videosRef);
        const result = [];
        for (const doc of videosSnapshot.docs) {
            result.push(doc.data());
        }
        state.allVideos = result;
    }

    async loadSelectedVideo(videoId) {
        if (state.allVideos === null) {
            const videoRef = doc(firestore, "/videos", videoId);
            const videoSnapshot = await getDoc(videoRef);
            if (videoSnapshot.exists()) {
                state.selectedVideo = videoSnapshot.data();
            } else {
                //console.log("Video not found: " + videoId);
                state.selectedVideo = null;
            }
        } else {
            state.selectedVideo = state.allVideos.find(video => video.id === videoId);
            if (state.selectedVideo === undefined) {
                const videoRef = doc(firestore, "/videos", videoId);
                const videoSnapshot = await getDoc(videoRef);
                if (videoSnapshot.exists()) {
                    state.selectedVideo = videoSnapshot.data();
                } else {
                    //console.log("Video not found: " + videoId);
                    state.selectedVideo = null;
                }
            }
        }
    }
}

export const contentService = new ContentServiceImpl();