import { createStore } from "@stencil/store";
import { contentService } from "../services/content.service";

const { state, onChange } = createStore({
    //ROUTER
    urlFragment: null,
    history: [],

    //UI CONFIGS
    viewportSize: null,

    //VIDEOS
    allVideos: null,
    selectedVideoId: null,
    selectedVideo: null,
});

onChange('selectedVideoId', (selectedVideoId) => {
    contentService.loadSelectedVideo(selectedVideoId);
});



export default state;